// RequireAuth.js
import React from "react";
import { useAuth } from "./AuthProvider";
import { Navigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const { user } = useAuth();

  if (!user) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default RequireAuth;
