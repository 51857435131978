import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./components/AuthProvider";

import LoginScreen from "./components/loginScreen";
import LoggedInScreen from "./components/loggedInScreen";
import RegisterUnluckyPage from "./components/registerUnluckyPage";
import Home from "./components/home";
import Profile from "./components/profile";
import CreateContent from "./components/createContent";
import RequireAuth from "./components/RequireAuth";
function App() {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginScreen />} />
            <Route
              path="/logged"
              element={
                <RequireAuth>
                  <LoggedInScreen />
                </RequireAuth>
              }
            />
            <Route
              path="/create"
              element={
                <RequireAuth>
                  <CreateContent />
                </RequireAuth>
              }
            />
            <Route
              path="/home"
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route
              path="/profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route path="/unlucky" element={<RegisterUnluckyPage />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;
