import "./createContentStyle.css";
import { useEffect, useState } from "react";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { app } from "../firebase";
import {
  getAuth,
  onAuthStateChanged,
  updateProfile,
  getDisplayName,
} from "firebase/auth";
import { uploadBytes, getDownloadURL, getStorage, ref } from "firebase/storage";
import { getApp } from "firebase/app";

const CreateContent = () => {
  // Get a non-default Storage bucket
  const firebaseApp = getApp();
  const [selectedImage, setSelectedImage] = useState(null);
  const auth = getAuth();
  const db = getFirestore(app);

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  let today = new Date();
  const time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

  const handleImageChange = (event) => {
    console.warn(
      "THERE IS SOME TRAFFIC GOING ON BETWEEN THIS AND YOUR BACKEND!"
    );
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        // Convert data URL to Blob
        const blob = await fetch(reader.result).then((response) =>
          response.blob()
        );
        // Check if the file size is less than 1MB (1MB = 1024 * 1024 bytes)
        if (blob.size < 1024 * 1024) {
          setSelectedImage(blob);
        } else {
          console.log("File size exceeds 1MB limit");
          // You might want to clear the selected image here or show an error message to the user
          setSelectedImage(null);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    console.warn(
      "THERE IS SOME TRAFFIC GOING ON BETWEEN THIS AND YOUR BACKEND!"
    );

    try {
      // Upload the image to Firebase Storage
      if (selectedImage) {
        const newUUID = uuidv4();
        //database eintrag
        setDoc(doc(db, "posts", newUUID), {
          ersteller: auth.currentUser.displayName,
          likes: 0,
          zeit: today,
          uuid: newUUID,
        });
        //picture storage upload
        const storage = getStorage(firebaseApp);
        const storageRef = ref(storage, "post_images/" + newUUID);
        const imageSnapshot = await uploadBytes(storageRef, selectedImage);
        const imageUrl = await getDownloadURL(imageSnapshot.ref);
        console.log("uploaded!");
      } else {
        console.log("no image selected!");
      }
    } catch (error) {
      // An error occurred
      console.log("Error while uploading: " + error.message);
    }
  };

  // const resizeImage = async (blob) => {
  //   return new Promise((resolve) => {
  //     const img = new Image();
  //     img.src = URL.createObjectURL(blob);

  //     img.onload = () => {
  //       const canvas = document.createElement("canvas");
  //       const ctx = canvas.getContext("2d");

  //       // Resize the image to a reasonable size (e.g., 800x800)
  //       const maxWidth = 800;
  //       const maxHeight = 800;
  //       let newWidth = img.width;
  //       let newHeight = img.height;

  //       if (img.width > maxWidth || img.height > maxHeight) {
  //         const aspectRatio = img.width / img.height;
  //         if (aspectRatio > 1) {
  //           newWidth = maxWidth;
  //           newHeight = maxWidth / aspectRatio;
  //         } else {
  //           newHeight = maxHeight;
  //           newWidth = maxHeight * aspectRatio;
  //         }
  //       }

  //       canvas.width = newWidth;
  //       canvas.height = newHeight;

  //       // Draw the image on the canvas
  //       ctx.drawImage(img, 0, 0, newWidth, newHeight);

  //       // Convert the canvas content to a blob
  //       canvas.toBlob((resizedBlob) => {
  //         resolve(resizedBlob);
  //       }, blob.type);
  //     };
  //   });
  // };
  return (
    <div className="masterDivUpload">
      <form className="form-container-upload" onSubmit={handleUpload}>
        <div className="pNameAndPicUpload">
          <input
            id="selectPicInputUpload"
            className="selectPicUpload"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
          <label
            htmlFor="selectPicInputUpload"
            className="selectPicLabelUpload"
          >
            Choose Image
          </label>

          {selectedImage && (
            <div>
              <img
                className="selectedImageUpload"
                src={URL.createObjectURL(selectedImage)}
                alt="Selected"
              />
            </div>
          )}
        </div>

        {/* <br />
          <label>
            <input 
              className="inputProfile"
              type="text"
              name="Vorname"
              placeholder="Vorname"
              // value={}
              // onChange={(e) => setPassword(e.target.value)}
            />
          </label> */}
        <button type="submit" className="uploadBTN">
          Hochladen
        </button>
        <p className="messageTextProfile">Dieser Upload kann jeder sehen.</p>
      </form>
    </div>
  );
};

export default CreateContent;
