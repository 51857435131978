import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  startAfter,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { app } from "../firebase";

import "./homeScreenStyle.css";

const HomeScreen = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [likedPosts, setLikedPosts] = useState([]);
  const [fetchedLikedPosts, setFetchedLikedPosts] = useState(false);

  const db = getFirestore(app);
  const postsCollection = collection(db, "posts");
  const auth = getAuth();

  const loadMorePosts = async () => {
    if (loading) return; // Prevent multiple simultaneous requests

    setLoading(true);

    try {
      const lastDoc = images[images.length - 1];
      const lastDocTime = lastDoc?.zeit;

      console.log("lastDocTime:", lastDocTime); // Log the value for debugging

      // Check if lastDocTime is defined and not null before using it
      if (lastDocTime !== undefined && lastDocTime !== null) {
        const q = query(
          postsCollection,
          orderBy("zeit", "desc"),
          startAfter(lastDocTime),
          limit(10)
        );

        const querySnapshot = await getDocs(q);

        const latestPosts = [];
        querySnapshot.forEach((doc) => {
          latestPosts.push(doc.data());
        });

        const imagesWithUrls = await Promise.all(
          latestPosts.map(async (post) => {
            const imageUrl = await getImageUrl(post.uuid);
            return { ...post, imageUrl };
          })
        );

        setImages((prevImages) => [...prevImages, ...imagesWithUrls]);
      } else {
        console.warn("lastDocTime is undefined or null");
      }
    } catch (error) {
      console.error("Error getting latest 10 documents:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchLikedPosts = async () => {
      try {
        const likedPostsSnapshot = await getDoc(
          doc(db, "likedPosts", auth.currentUser.uid)
        );

        if (likedPostsSnapshot.exists()) {
          const likedPostsJSON = likedPostsSnapshot.data();
          console.log("liked JSON: " + likedPostsJSON.likedPosts.toString());
          setLikedPosts(JSON.parse(likedPostsJSON.likedPosts));
          setFetchedLikedPosts(true);
        } else {
          console.log("Liked posts document does not exist");
        }
      } catch (error) {
        console.log("Error while fetching likedPosts: " + error);
      }
    };

    fetchLikedPosts();
  }, [auth]);

  const handleLike = async (zeit, ersteller, likes, uuid) => {
    try {
      // Update the document in the database
      await setDoc(doc(db, "posts", uuid.toString()), {
        ersteller: ersteller,
        likes: likedPosts.includes(uuid) ? likes - 1 : likes + 1,
        zeit: zeit,
        uuid: uuid,
      });

      // Update the likedPosts state
      setLikedPosts((prevLikedPosts) =>
        prevLikedPosts.includes(uuid)
          ? prevLikedPosts.filter((item) => item !== uuid)
          : [...prevLikedPosts, uuid]
      );

      // Update the images state for the specific post
      setImages((prevImages) =>
        prevImages.map((post) =>
          post.uuid === uuid
            ? { ...post, likes: likes + (likedPosts.includes(uuid) ? -1 : 1) }
            : post
        )
      );
    } catch (error) {
      console.log("Error liking/disliking this post!", error);
    }
  };

  useEffect(() => {
    if (fetchedLikedPosts) {
      setDoc(doc(db, "likedPosts", auth.currentUser.uid), {
        likedPosts: JSON.stringify(likedPosts),
      });
    }
  }, [likedPosts]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;

      const body = document.body;
      const html = document.documentElement;
      const documentHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      const windowBottom = windowHeight + window.pageYOffset;

      if (windowBottom >= documentHeight - 100 && !loading) {
        loadMorePosts();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, loadMorePosts]); // Include loading as a dependency

  useEffect(() => {
    getPosts();
  }, []); // Fetch posts when the component mounts

  const getPosts = async () => {
    try {
      const q = query(postsCollection, orderBy("zeit", "desc"), limit(10));
      const querySnapshot = await getDocs(q);

      const latestPosts = [];
      querySnapshot.forEach((doc) => {
        latestPosts.push(doc.data());
      });

      const imagesWithUrls = await Promise.all(
        latestPosts.map(async (post) => {
          const imageUrl = await getImageUrl(post.uuid);
          return { ...post, imageUrl };
        })
      );
      setImages(imagesWithUrls);
    } catch (error) {
      console.error("Error getting latest 10 documents:", error);
    }
  };

  const getImageUrl = async (uuid) => {
    const storage = getStorage(app);
    const imageRef = ref(storage, `post_images/${uuid}`);
    try {
      const imageUrl = await getDownloadURL(imageRef);
      return imageUrl;
    } catch (error) {
      console.error(`Error getting image URL for ${uuid}:`, error);
      return null;
    }
  };
  return (
    <div className="mainDivHomeScreen">
      <button onClick={getPosts} className="reloadButton">
        Reload
      </button>
      <div className="postsDiv">
        {images.map((post, index) => (
          <div key={index} className="postContainer">
            <p className="erstellerName">{post.ersteller}</p>
            <img src={post.imageUrl} alt={post.uuid} className="postImgS" />
            <div
              className="likesDiv"
              onClick={() =>
                handleLike(post.zeit, post.ersteller, post.likes, post.uuid)
              }
            >
              <img src="/sprites/hearth_1.png" alt="likes" />
              <p>{post.likes}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeScreen;
