// LoggedInScreen.js
import React from "react";
import { useLocation } from "react-router-dom";

const LoggedInScreen = () => {
  const location = useLocation();
  const { email} = location.state || {}; // Access the user information from the location state

  return (
    <div>{email ? `You are logged in as ${email}` : "User not found"}</div>
  );
};

export default LoggedInScreen;
