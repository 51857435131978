// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCAMXooLPZ2JGgG8fiP5ti1ahgtVrPx1OI",
  authDomain: "mofach231227.firebaseapp.com",
  projectId: "mofach231227",
  storageBucket: "mofach231227.appspot.com",
  messagingSenderId: "555804291740",
  appId: "1:555804291740:web:8a26dc512d621dceef3359",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Use getAuth with the initialized app
const db = getFirestore(app);

export { auth, app };
