import { useEffect, useState } from "react";
import "./profileStyle.css";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  onAuthStateChanged,
  updateProfile,
  getDisplayName,
} from "firebase/auth";
import { uploadBytes, getDownloadURL, getStorage, ref } from "firebase/storage";
import { getApp } from "firebase/app";
const Profile = () => {
  // Get a non-default Storage bucket
  const firebaseApp = getApp();
  const [selectedImage, setSelectedImage] = useState(null);
  const [username, setUsername] = useState("");
  const [userImage, setUserImage] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    console.warn(
      "THERE IS SOME TRAFFIC GOING ON BETWEEN THIS AND YOUR BACKEND!"
    );

    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        // Convert data URL to Blob
        const blob = await fetch(reader.result).then((response) =>
          response.blob()
        );

        // Check if the file size is less than 1MB (1MB = 1024 * 1024 bytes)
        if (blob.size < 1024 * 1024) {
          setSelectedImage(blob);
        } else {
          // File is too large, handle accordingly (e.g., display an error message)
          console.log("File size exceeds 1MB limit");
          // You might want to clear the selected image here or show an error message to the user
          setSelectedImage(null);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSave = async (e) => {
    e.preventDefault();
    console.warn(
      "THERE IS SOME TRAFFIC GOING ON BETWEEN THIS AND YOUR BACKEND!"
    );
    try {
      // Upload the image to Firebase Storage
      if (selectedImage) {
        const storage = getStorage(firebaseApp);
        const storageRef = ref(
          storage,
          "profile_images/" + auth.currentUser.displayName
        ); // Assuming you want to store images in a "profile_images" folder with the user's UID as the filename
        const imageSnapshot = await uploadBytes(storageRef, selectedImage);
        const imageUrl = await getDownloadURL(imageSnapshot.ref);

        // Update the user's profile with the new username and image URL
        await updateProfile(auth.currentUser, {
          displayName: username,
          photoURL: imageUrl,
        });
      } else {
        // If no image is selected, update the user's profile with only the new username
        await updateProfile(auth.currentUser, {
          displayName: username,
        });
      }

      // Profile updated!
      console.log("Profile updated!");
      navigate("/");
    } catch (error) {
      // An error occurred
      console.log("Error while updating profile: " + error.message);
    }
  };

  useEffect(() => {
    const fetchUser = () => {
      onAuthStateChanged(auth, (user) => {
        console.log("fetching user");
        if (user) {
          //user is signed in
          setUsername(user.displayName);
          fetchUserProfileImage(user.displayName);
        } else {
          // User is signed out
          setUserImage(null);
        }
      });
    };

    fetchUser();
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  const fetchUserProfileImage = async (displayName) => {
    console.warn(
      "THERE IS SOME TRAFFIC GOING ON BETWEEN THIS AND YOUR BACKEND!"
    );
    try {
      const storage = getStorage(firebaseApp);
      const storageRef = ref(storage, "profile_images/" + displayName);
      const imageUrl = await getDownloadURL(storageRef);

      // Set the user's profile image
      setUserImage(imageUrl);
    } catch (error) {
      console.log("Error fetching user profile image: " + error.message);
    }
  };

  return (
    <div className="mainDivProfile">
      <div className="secondDivProfile">
        <form className="form-container-profile" onSubmit={handleSave}>
          <div className="pNameAndPic">
            <label>
              <input
                className="inputProfile"
                type="text"
                name="username"
                placeholder="Benutzername"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </label>
            <input
              id="selectPicInput"
              className="selectPic"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            <label htmlFor="selectPicInput" className="selectPicLabel">
              Choose Image
            </label>

            {selectedImage && (
              <div>
                <img
                  className="selectedImage"
                  src={URL.createObjectURL(selectedImage)}
                  alt="Selected"
                />
              </div>
            )}

            {userImage && (
              <div className="imageNow">
                <img
                  src={userImage}
                  alt="Profile"
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                />
              </div>
            )}
          </div>

          {/* <br />
          <label>
            <input 
              className="inputProfile"
              type="text"
              name="Vorname"
              placeholder="Vorname"
              // value={}
              // onChange={(e) => setPassword(e.target.value)}
            />
          </label> */}
          <br />
          <p className="messageTextProfile">
            Wenn du Speicherst, musst du dich danach erneut anmelden.
          </p>
          <br />
          <button type="submit" className="backBTNProfile">
            Speichern
          </button>
        </form>
      </div>
    </div>
  );
};

export default Profile;
