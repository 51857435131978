import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "./loginScreenStyle.css";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";

const LoginScreen = () => {
  const [email, setEmail] = useState(""); //this email is the email, that the user types in
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState(true);

  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    console.log("Email:", email);
    console.log("Password:", password);

    console.warn(
      "THERE IS SOME TRAFFIC GOING ON BETWEEN THIS AND YOUR BACKEND!"
    );

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
        // Signed in
        const user = userCredential.user;
        console.log("Signed in as:", user.email);

        navigate("/home");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Authentication error:", errorCode, errorMessage);
      });
  };

  return (
    <div className="centered">
      <h1 className="h1">MofaCH</h1>
      <div className="master">
        <div className="firstDiv">
          <h2 className="h2">{login ? "Anmelden" : "Registrieren"}</h2>
          <button
            onClick={() => {
              navigate("/unlucky");
            }}
            //() => setLogin((prevLogin) => !prevLogin)
            className="askButton"
          >
            {login ? "Noch kein Account?" : "Hier anmelden!"}
          </button>
        </div>
        <form className="form-container" onSubmit={handleLogin}>
          <label>
            <input
              className="input"
              type="text"
              name="email"
              placeholder="E-Mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <br />
          <label>
            <input
              className="input"
              type="password"
              name="password"
              placeholder="Passwort"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <br />
          <button type="submit" id="loginButton">
            {login ? "Anmelden" : "Registrieren"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
