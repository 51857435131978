import React, { useEffect, useState } from "react";
import "./homeStyle.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ForcePasswordInput from "./forcePasswordInput";
import Profile from "./profile";
import HomeScreen from "./homeScreen";
import Search from "./search";
import CreateContent from "./createContent";

const Home = () => {
  // const navigate = useNavigate();
  const auth = getAuth();
  const [userEmail, setUserEmail] = useState();
  const [forceUsernameInput, setForceUsernameInput] = useState(false);
  const [username, setUsername] = useState("");

  const [page, setPage] = useState("home");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUsername(user.displayName);
        setUserEmail(user.email);
        setForceUsernameInput(user.displayName === null);
      } else {
        // User is signed out
      }
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth]); // Only re-run the effect if `auth` changes

  const updateForceUsernameInput = (value) => {
    setForceUsernameInput(value);
  };

  return (
    <>
      <header>
        <div className="headerDiv"></div>
        <div className="headerDiv"></div>
        <div>
          <img
            onClick={() => {
              setPage("home");
            }}
            className="headerDiv"
            src="/sprites/home_icon.webp"
            alt="plus icon"
          ></img>
        </div>
        <div>
          <img
            onClick={() => {
              setPage("create");
            }}
            className="headerDiv"
            src="/sprites/plus_icon.png"
            alt="plus icon"
          ></img>
        </div>
        <div>
          <img
            className="headerDiv"
            onClick={() => {
              setPage("search");
            }}
            src="/sprites/Search_icon.png"
            alt="search icon"
          ></img>
        </div>
        <div className="profileDiv">
          <p
            onClick={() => {
              setPage("profile");
            }}
          >
            {username}
          </p>
          <img
            className="headerDiv"
            onClick={() => {
              setPage("profile");
            }}
            src="/sprites/profile_icon.png"
            alt="Profile Icon"
          />
        </div>
      </header>
      <main>
        {forceUsernameInput === true && (
          <ForcePasswordInput
            updateForceUsernameInput={updateForceUsernameInput}
          />
        )}
        <div>
          {page === "home" && <HomeScreen />}
          {page === "profile" && <Profile />}
          {page === "create" && <CreateContent />}
          {page === "search" && <Search />}
        </div>
      </main>
    </>
  );
};

export default Home;
