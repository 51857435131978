import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateProfile, getAuth } from "firebase/auth";
import { app } from "../firebase";
import { doc, getFirestore, setDoc } from "firebase/firestore";

const ForcePasswordInput = ({ updateForceUsernameInput }) => {
  const [username, setUsername] = useState();

  const db = getFirestore(app);

  const navigate = useNavigate();

  const auth = getAuth();

  const handleChangeUsername = async (e) => {
    e.preventDefault();
    console.log("Username:", username);

    const user = auth.currentUser;

    // Update user profile
    updateProfile(user, {
      displayName: username,
    })
      .then(() => {
        console.log("Username updated!");
        updateForceUsernameInput(false);

        // Add user data to Firestore
        const usersCollection = doc(db, "users", user.uid);

        setDoc(usersCollection, {
          displayName: username,
          email: auth.currentUser.email,
        })
          .then(() => {
            console.log("Document successfully written!");
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });

        navigate("/");
      })
      .catch((error) => {
        console.log("Error while updating username: " + error);
      });
  };

  return (
    <div className="backgroundDiv">
      <div className="forceUsernameDiv">
        <p>Please enter a username to continue.</p>
        <form className="form-container" onSubmit={handleChangeUsername}>
          <label>
            <input
              type="text"
              name="username"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>
          <button type="submit" id="setUsernameButton">
            set Username
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForcePasswordInput;
