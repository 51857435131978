import { useState } from "react";
import "./searchStyle.css";

const Search = () => {
  const [searchedDisplayName, setSearchedDisplayName] = useState("");
  const [foundUser, setFoundUser] = useState(null);

  const handleSearch = async (e) => {
    e.preventDefault();
    console.log("Suche: " + searchedDisplayName);
  };

  return (
    <div className="mainDivSearch">
      <div className="searchDiv">
        <p>Search</p>
        <form className="form-container" onSubmit={handleSearch}>
          <label>
            <input
              className="input"
              type="text"
              name="displayname"
              placeholder="Benutzername"
              value={searchedDisplayName}
              onChange={(e) => setSearchedDisplayName(e.target.value)}
            />
          </label>
          <button type="submit" id="loginButton">
            Suchen
          </button>
        </form>
      </div>
    </div>
  );
};
export default Search;
