import { useNavigate } from "react-router-dom";
import "./registerUnluckyPageStyle.css";
import React from "react";

const RegisterUnluckyPage = () => {
  const navigate = useNavigate();
  return (
    <div className="mainDiv">
      <h1 className="h1Unlucky">Du hast keinen Account.</h1>
      <p className="mainMessage">
        {" "}
        Momentan ist eine Registrierung nicht möglich. Grüsse gehen raus an Ivo
        und Patrick.
      </p>
      <button
        id="backBTNUnlucky"
        onClick={() => {
          navigate("/");
        }}
      >
        Zurück
      </button>
    </div>
  );
};

export default RegisterUnluckyPage;
